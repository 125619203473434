<template>
    <b-modal id="modal-video-preview" scrollable title="Pesan Video" hide-footer>
        <form class="form-horizontal form-material">
            <div class="form-group">
                <div class="col-md-12">
                    <div>
                        <video style="max-width: 100%;" :src="videoUrl" controls></video>
                    </div>
                    <div class="img-caption">{{ caption }}</div>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
    export default {
        props: ['videoUrl', 'caption']
    }
</script>