<template>
    <b-modal id="reopen-chat-dialog" title="Chat Kembali">
        <div class="modal-body">Memulai kembali percakapan dengan {{contactName}}?</div>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-default" @click="$bvModal.hide('reopen-chat-dialog')">Tidak</button>
            <button type="button" @click="reopenChat" class="btn btn-primary tst3"><i v-if="isLoading"
                    class="fa fa-circle-o-notch fa-spin"></i>Ya</button>
        </template>
    </b-modal>
</template>

<script>
    import axios from 'axios'

    export default {
        data: function () {
            return {
                isLoading: false
            }
        },
        methods: {
            reopenChat: async function () {
                this.isLoading = true
                try {
                    await axios.put(process.env.VUE_APP_API_URL + '/contact/status/' + this.contactId, {
                        status: 'open'
                    });

                    this.$emit('reopen-callback', true, this.contactId)
                } catch (error) {
                    this.$emit('reopen-callback', false, error)
                }
                this.$bvModal.hide("reopen-chat-dialog");
                this.isLoading = false
            }
        },
        props: ['contactId', 'contactName']
    }
</script>