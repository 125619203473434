module.exports = [{
        title: 'Status Pesanan',
        group: 'order',
        template: 'status pesanan dengan no pesanan <%= item.formalId%> yaitu pesanan <%= item.status == \'COMPLETE\' ? \'selesai\' : item.status == \'DRAFT\' ? \'disimpan\' : item.status == \'BOOKED\' ? \'dibuat\' : \'dibatalkan\' %>'
    },
    {
        title: 'Status Pembayaran',
        group: 'order',
        template: 'status pembayaran dengan no pesanan <%= item.formalId%> yaitu pesanan <%= item.paymentStatus == \'PAID_PARTIAL\' ? \'dibayar sebagian\' : item.paymentStatus == \'VALIDATING\' ? \'pengecekan pembayaran\' : item.paymentStatus == \'FROM_RETURN\' ? \'pembayaran dikembalikan\' : item.paymentStatus == \'UNPAID\' ? \'pembayaran dibatalkan\' : item.paymentStatus == \'DRAFT\' ? \'belum dibayar\' : \'open\' %>'
    },
    {
        title: 'Status Pengiriman',
        group: 'order',
        template: 'status pengiriman dengan no pesanan <%= item.formalId%> yaitu pesanan <%= item.shipmentStatus == \'SHIPPED_PARTIAL\' ? \'dikirim sebagian\' : item.shipmentStatus == \'BOOKED\' ? \'kurir terpilih\' : item.shipmentStatus == \'UNSET\' ? \'kurir belum terpilih\' : item.shipmentStatus == \'DRAFT\' ? \'barang sedang disiapkan\' : \'barang sedang dikemas\' %>'
    },
    {
        title: 'Ongkos Kirim',
        group: 'order',
        template: 'Kami informasikan untuk Ongkos kirimnya ya kak:)\n\nPesanan dengan No <%= item.formalId%>\n\nKontak: <%= item.shippingAddress.name%> - <%= item.shippingAddress.primaryMobile%>\nAlamat: <%= item.shippingAddress.street%>, <%= item.shippingAddress.district%>, <%= item.shippingAddress.city%>, <%= item.shippingAddress.province%>, ID <%= item.shippingAddress.postalCode%>\nKurir: <%= item.courier%> (<%= item.courierService%>) - Rp <%= item.shippingCost%>\nNo Resi: <%= item.shipmentInfo.awb%>\n\n<%= item.shipmentInfo && item.shipmentInfo.usingInsurance ? \'Pengiriman ini telah terlindungi proteksi asuransi. Terima Kasih\' : \'Pengiriman ini tidak menggunakan asuransi. Jika terdapat kerusakan atau kehilangan barang, untuk barang yang dikirim tanpa menggunakan asuransi akan mendapatkan penggantian maksimal 10x dari biaya pengiriman.\' %>'
    },
    {
        title: 'Total Pesanan',
        group: 'order',
        template: 'Total pesanan dengan no <%= item.formalId%> yang telah dibeli yaitu sebesar *Rp <%= item.amount%>* dengan rincian masing - masing product:\n<% item.lines.forEach( e => {%>\n<%= e.product.name%> - <%= e.qty%> <%= e.qty > 1 ? \'pcs\' : \'pc\' %> -<%= e.price != e.finalPrice ? \'  ~Rp \' + e.price + \'~\': \'\'%> (Rp <%= e.finalPrice%>)<%})%>\n\n<%= item.shipmentInfo && item.shipmentInfo.usingInsurance ? \'Pengiriman ini telah terlindungi proteksi asuransi. Terima Kasih\' : \'Pengiriman ini tidak menggunakan asuransi. Jika terdapat kerusakan atau kehilangan barang, untuk barang yang dikirim tanpa menggunakan asuransi akan mendapatkan penggantian maksimal 10x dari biaya pengiriman.\' %>'
    },
    {
        title: 'Total Pesanan Dengan Rincian',
        group: 'order',
        template: 'Nominal total pesanan no <%= item.formalId%> yang telah dipesan yaitu sebesar *Rp <%= item.amount%>* dengan rincian pembelian:\n<% item.lines.forEach( e => {%>\n<%= e.product.name%> - <%= e.qty%> <%= e.qty > 1 ? \'pcs\' : \'pc\' %> -<%= e.price != e.finalPrice ? \'  ~Rp \' + e.price + \'~\': \'\'%> (Rp <%= e.finalPrice%>)<%})%>\n\nbiaya pengiriman Rp <%= item.originalShippingCost%>\nsubtotal Rp <%= item.linesAmount%>\n\npromo keagenan Rp <%= item.discountPriceTier%>\npromo produk spesial Rp <%= item.discountProductSpecial%>\npromo pengiriman Rp <%= item.shippingDiscountAmount%>\npromo total belanja Rp <%= item.totalDiscountAmount%>\n\n<%= item.shipmentInfo && item.shipmentInfo.usingInsurance ? \'Pengiriman ini telah terlindungi proteksi asuransi. Terima Kasih\' : \'Pengiriman ini tidak menggunakan asuransi. Jika terdapat kerusakan atau kehilangan barang, untuk barang yang dikirim tanpa menggunakan asuransi akan mendapatkan penggantian maksimal 10x dari biaya pengiriman.\' %>'
    },
    {
        title: 'Rincian Produk',
        group: 'order',
        template: 'Kami informasikan untuk rincian produknya ya kak:)\n\nPesanan no <%= item.formalId %> dengan rincian produk yang dipesan, yaitu:\n<% if(item.lines.length > 0) {%><% item.lines.forEach(line => {%>\n- <%=line.product.localSku %> <%= line.properties != null ? line.properties.map(e => e.name) : "" %> <%=line.qty%> <%=line.qty > 1 ? \'pcs\' : \'pc\'%><% })} %>'
    },
    {
        title: 'Bank Transfer',
        group: 'pembayaran ',
        template: 'Baik kak, untuk nomor nota pemesanannya <%= item.formalId%> dengan total Rp <%= item.amount%> bisa transfer ke salah satu no rekening berikut ya kak \n\n- BCA: 777-157-0007 a/n PT Bina Fajar Estetika, \n- BSI: 720-090-0997 a/n PT. Bina Fajar Estetika\n- multipayment MANDIRI dengan kode perusahaan 40020 . \n\nMohon lampirkan bukti trf jika sudah melakukan pembayaran dan mohon cantumkan nomor nota pada keterangan pembayaran. terima kasih'
    },
    {
        title: 'Payment Gateway',
        group: 'pembayaran ',
        template: 'pembayaran pesanan no <%= item.formalId%> melalui <%= gateway.mitraName%> dengan nominal sebesar Rp <%= item.amount%>\n\nVA Number :  <%= gateway.paymentNumber%>\nBatas Pembayaran :  <%= gateway.paymentValidTime%>'
    },
    {
        title: 'No Resi + Kurir',
        group: 'order',
        template: 'Berikut saya lampirkan untuk no resi pesanan kk:\n<% listDetail.forEach( e => {%>\nNo nota *<%= e.formalId%>*<%= e.awbNumber ? \', dengan no Resi *\' + e.awbNumber + \'*\' : \'\'%><%= e.courier != \'NONE\' ? \', dikirim menggunakan kurir *\' + e.courier + \'*\' : \'\'%><%= e.shipmentInfo && e.shipmentInfo.usingInsurance ? \', -Asuransi\' : \', -Non Asuransi\'%><% })%>'
    },
    {
        title: 'No Resi + Kurir + Alamat',
        group: 'order',
        template: 'Berikut saya lampirkan untuk no resi pesanan kk:\n<% listDetail.forEach( e => {%>\nNo nota *<%= e.formalId%>*<%= e.awbNumber ? \', dengan no Resi *\' + e.awbNumber + \'*\' : \'\'%><%= e.shipmentInfo && e.shipmentInfo.usingInsurance ? \', -Asuransi\' : \', -Non Asuransi\'%>\n<%= e.courier != \'NONE\' ? \'Dikirim menggunakan kurir *\' + e.courier + \'* \'  : \'\'%><% if(e.shippingAddress) {%>Alamat *<%= e.shippingAddress.street ? e.shippingAddress.street + \', \' : \'\'%><%= e.shippingAddress.district%>, <%= e.shippingAddress.city%>, <%= e.shippingAddress.province%>. <%= e.shippingAddress.postalCode%>*<% }})%>'
    },
    {
        title: 'Dropship',
        group: 'order',
        template: 'Nominal pembelanjaan untuk no nota *<%= item.formalId%>* yaitu sebesar *Rp <%= item.amount%>*, dengan rincian:\n<% if(item.lines.length > 0) { item.lines.forEach(line => { %>\n<%= line.product.name%> - <%= line.product.localSku%> <%= line.properties != null ? line.properties.map(e => e.name) : "" %> <% if(line.price == line.finalPrice) { %>*Rp <%= line.finalPrice%>*<% } else {%>~Rp <%= line.price%>~ *Rp <%= line.finalPrice%>*<% }})}%><% if(item.shippingCost > 0) {%>\n\nTujuan pengiriman <% if(item.dropshipOriginAddress) { %><%= item.dropshipOriginAddress.name %> <%= item.dropshipOriginAddress.primaryMobile %> dengan <% } %><%= item.shippingAddress ?  \'*\' + item.shippingAddress.name + \'*\' : \'\' %> menggunakan *<%= item.shipmentInfo.courier%>(<%= item.shipmentInfo.service%>)* nominal ongkir *Rp <%= item.shippingCost%>*<% } %>\n\n<%= item.shipmentInfo && item.shipmentInfo.usingInsurance ? \'Pengiriman ini telah terlindungi proteksi asuransi. Terima Kasih\' : \'Pengiriman ini tidak menggunakan asuransi. Jika terdapat kerusakan atau kehilangan barang, untuk barang yang dikirim tanpa menggunakan asuransi akan mendapatkan penggantian maksimal 10x dari biaya pengiriman.\' %>'
    },
    {
        title: 'Total Dropship',
        group: 'order',
        template: 'Total pembelanjaan untuk nota dropship yaitu sebesar Rp <%= total.amount%>. dengan rincian sebagai berikut\n<% total.orderList.forEach( e => {%>\n*<%= e.formalId%>* <%= e.dropshipOriginAddress ? \'dengan nama pengirim *\' + e.dropshipOriginAddress.name + \'* \' : \'\' %>sebesar *Rp <%= e.amount%>*<%= e.shipmentInfo && e.shipmentInfo.usingInsurance ? \', -Asuransi\' : \', -Non Asuransi\'%><%})%>'
    },
]