module.exports = [{
        title: 'Link Produk',
        group: 'produk',
        template: '\nBerikut ini kami kirimkan link produknya ya kak:)'
    },
    {
        title: 'Video',
        group: 'produk',
        template: '\nBerikut ini kami kirimkan link video produknya ya kak:)'
    },
    {
        title: 'Info Stok',
        group: 'produk',
        template: 'Kami informasikan untuk ketersediaan produk saat ini\n\n<% if(item.price == item.finalPrice) { %><%= item.localSku%> - <%= item.name%> tersedia dengan harga Rp. <%=item.price %><% } else {%><%= item.localSku%> - <%= item.name%>\nsedang discount <%= discount%>% dari harga normal Rp. <%= item.price%> menjadi Rp. <%= item.finalPrice%><% }%>\n<% data.forEach(e => {%>\n<%= e.properties[0].name%><%= e.properties[1] ? ", " + e.properties[1].name : ""%> - <%= 1 > item.qty || e.qtyBalanceValue.qtyAvailable == 0 ? "Habis" : e.qtyBalanceValue.qtyAvailable < 4 ? e.qtyBalanceValue.qtyAvailable : e.qtyBalanceValue.qtyAvailable < 7  ? "Stok Menipis" : "Tersedia" %><%= 1 > item.qty || e.qtyBalanceValue.qtyAvailable == 0 || 4 < item.qty ? "" : e.qtyBalanceValue.qtyAvailable > 1 ? " pcs" : " pc"%><% }) %>\n\nStok terbatas ya Kak, segera lakukan pemesanan sebelum produk habis😊'
    },
    {
        title: 'Info Stok Retail',
        group: 'produk',
        template: 'Kami informasikan untuk ketersediaan produk saat ini\n\n<% if(item.price == item.finalPrice) { %><%= item.localSku%> - <%= item.name%> tersedia dengan harga Rp. <%=item.price %><% } else {%><%= item.localSku%> - <%= item.name%>\nsedang discount <%= discount%>% dari harga normal Rp. <%= item.price%> menjadi Rp. <%= item.finalPrice%><% }%>\n<% data.forEach(e => {%>\n<%= e.properties[0].name%><%= e.properties[1] ? ", " + e.properties[1].name : ""%> - <%= 1 > item.qty ? "Habis" : e.qtyBalanceValue.qtyAvailable == 0 ? "Habis" : "Tersedia"%><% }) %>\n\nNamun stok ini akan mengalami perubahan setiap saat ya kak:)'
    },
]