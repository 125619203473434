<template>
    <v-dialog v-model="showDialog" persistent @keydown.esc="closeDialog" transition="dialog-bottom-transition"
        max-width="600">
        <template>
            <v-card>
                <v-toolbar color="deep-purple accent-4" dark class="text-h6 text-white" style="background-color:red">
                    <span class="text-white">Tutup percakapan dengan '{{ contactName }}'</span>
                </v-toolbar>
                <v-form>
                    <v-card-text>
                        <div class="mb-2 mt-2">
                            <div class="pull-left"><span class="text-muted">Order:</span> <b>{{ summaryOrderList }}</b></div> 
                            
                        </div>
                        <div class="clearfix"></div>
                        <div class="mb-2 mt-2">
                            <div class="pull-left"><span class="text-muted">Retur:</span> <b>{{ summaryReturnList }}</b></div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="mb-2 mt-2">
                            <div class="pull-left"><span class="text-muted">Komplain:</span> <b>{{ summaryComplainList }}</b></div>
                        </div>
                        <div class="clearfix"></div>
                        <v-textarea class="mt-4" outlined name="note" label="Masukan Keterangan"></v-textarea>
                    </v-card-text>
                    <v-card-actions class="justify-end p-3">
                        <v-btn @click="closeDialog">
                            Tidak
                        </v-btn>
                        <v-btn class="ml-2 btn btn-primary" style="color: white;"
                            @click="closeChat"
                        >
                            Ya
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
    <!-- <b-modal id="close-chat-dialog" title="Tutup Percakapan">
        <div class="modal-body">Tutup percakapan dengan '{{ contactName }}'?
            <br>

            <form class="mt-4">
                <div class="form-group">
                     <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Masukan keterangan" rows="3"></textarea>
                </div>
            </form>
        </div>
        <div>
            Sales Order: {{ summaryOrderList }}
        </div>
        <div>
            Retur: {{ summaryReturnList }}
        </div>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-default" @click="$bvModal.hide('close-chat-dialog')">Tidak</button>
            <button type="button" @click="closeChat" class="btn btn-primary tst3"><i v-if="isLoading"
                    class="fa fa-circle-o-notch fa-spin"></i>Ya</button>
        </template>
    </b-modal> -->
</template>

<script>
    import axios from 'axios'

    export default {
        data: function () {
            return {
                isLoading: false,
                formValid: false,
                summaryOrderList: String(),
                summaryReturnList: String(),
                summaryComplainList: String(),
            }
        },
        methods: {
            closeChat: async function () {
                this.isLoading = true
                try {
                    await axios.put(process.env.VUE_APP_API_URL + '/contact/status/' + this.contactId, {
                        status: 'closed',
                        sessionId: this.sessionId
                    });

                    this.$emit('close-callback', true)
                } catch (error) {
                    this.$emit('close-callback', false, error)
                }
                this.closeDialog()
                // this.$bvModal.hide("close-chat-dialog");
                this.isLoading = false

            },
            formatSelectedComplainType(items) {
                return items.map(e => {
                    if (e == 'DEFECT') return 'PRODUK_CACAT'
                    if (e == 'SHIPPING') return 'PENGIRIMAN'
                    if (e == 'WEBSITE') return 'WEBSITE'
                    if (e == 'APPS') return 'APLIKASI'
                    if (e == 'CS_BAD_SERVICE') return 'PELAYANAN_TIDAK_SESUAI'
                    if (e == 'STOCK') return 'STOK_PRODUK'
                    if (e == 'POINT') return 'POIN'
                    if (e == 'RETURN') return 'RETUR'
                    if (e == 'PROMO') return 'PROMO'
                    if (e == 'PAYMENT') return 'PEMBAYARAN'
                    if (e == 'OTHER') return 'LAINNYA'
                })
            },
            closeDialog: function () {
                this.$emit('close-dialog', false)
            },
            getSummary: async function(sessionId) {
                this.isLoading = true
                const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/detail/' + sessionId, {
                    sort: 'createdOn'
                });

                if(data) {
                    this.summaryOrderList = data.salesOrders.map(e => e.formalId).join(', ')
                    this.summaryReturnList = data.returnOrders.map(e => e.formalId).join(', ')
                    let temp = Array()
                    
                    data.kinds.forEach(e => {
                        if(e.refMsgIds.length > 0) temp.push(e.code) 
                    })

                    this.summaryComplainList = this.formatSelectedComplainType(temp).join(', ')
                }
                this.isLoading = false
            },
        },

        props: ['showDialog', 'contactId', 'contactName', 'sessionId'],

        watch: {
            showDialog: function(val) {
                if(val) {
                    if(this.sessionId) this.getSummary(this.sessionId)
                }
            }
        }
    }
</script>