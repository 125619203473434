<template>
    <b-modal id="send-video-modal" title="Kirim Video" @show="resetModal">
        <div class="">
            <form class="form-horizontal form-material">
                <div class="form-group">
                    <div class="col-md-12" v-if="urlVideo">
                        <video style="max-width:100%" v-bind:src="urlVideo" controls></video>
                    </div>
                    <div class="col-md-12">
                        <input type="file" id="file" ref="file" v-on:change="handleFileUpload" accept=".mp4,.mov,.mkv,.webm"/>
                    </div>
                    <div class="col-md-12">
                        <input type="text" class="form-control" placeholder="Caption" v-model="caption">
                        <div class="form-control-feedback text-danger">
                        </div>
                    </div>
                    <!--div class="col-md-12 m-b-20">
                        <input type="textarea" class="form-control" placeholder="Deskripsi" v-model="messageText">
                        <div class="form-control-feedback text-danger">
                        </div>
                    </div-->
                    
                </div>
            </form>
        </div>
        <b-progress v-if="isSendInProgress" :value="uploadPercentage" show-progress animated></b-progress>
        <ErrorMessage v-bind:error="error" />
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-default waves-effect"
                @click="$bvModal.hide('send-video-modal')">Batal</button>
            <button type="button" class="btn btn-default waves-effect" id="btn-send-image" @click="uploadFile">
                <i v-if="isSendInProgress" class="fa fa-circle-o-notch fa-spin"></i> &nbsp;Upload ke media</button>
            <button type="button" class="btn btn-primary waves-effect" id="btn-send-image" @click="submitFile">
                <i v-if="isSendInProgress" class="fa fa-circle-o-notch fa-spin"></i> &nbsp;Kirim</button>
        </template>
    </b-modal>
</template>

<script>
    import ErrorMessage from '../components/ErrorMessage'
    import axios from 'axios'
    const uuidv4 = require('uuid/v4')

    export default {
        data: function () {
            return {
                file: null,
                urlVideo: '',
                caption: '',
                messageText: '',
                error: '',
                isSendInProgress: false,
                uploadPercentage: 0
            }
        },
        methods: {
            resetModal: function () {
                this.file = null;
                this.caption = '';
                this.urlVideo = '';
                this.messageText = '';
                this.error = {};
                this.isSendInProgress = false;
                this.uploadPercentage = 0;
            },
            handleFileUpload: function () {
                this.error = {}

                if(this.$refs.file.files[0].size > 16000000){
                    this.error = this.$helpers.createError({
                        message: "Max file size 16mb"
                    })
                    document.getElementById("file").value=null;
                    return
                }

                this.file = this.$refs.file.files[0];
                var reader = new FileReader();

                var app = this;


                reader.onloadend = function () {
                    app.urlVideo = reader.result;
                }

                if (this.file) {
                    reader.readAsDataURL(this.file);
                } else {
                    this.urlVideo = '';
                }
                this.error = {};
            },
            submitFile: async function () {
                var appSubmit = this;
                this.error = {}

                if (!this.$helpers.isBlank(this.file)) {
                    this.isSendInProgress = true;
                    this.uploadPercentage = 0;
                    let formData = new FormData();
                    formData.append('file', this.file);
                    formData.append('caption', this.caption);
                    //formData.append('description', this.messageText);
                    formData.append('id', uuidv4());
                    formData.append('channel', this.channel);
                    formData.append('channelId', this.sourceChannelId);
                    formData.append('number', this.destNumber);
                    // if (this.channel == 'FB') {
                    //     formData.append('number', this.destNumber);
                    // } else if (this.channel == 'SMS' || this.channel == 'WA') {
                    //     formData.append('number', this.destNumber);
                    // }

                    if (this.qmOriginId) {
                        formData.append('qmOriginId', this.qmOriginId)
                        formData.append('qmText', this.qmText)
                        formData.append('qmOriginExternalId', this.qmOriginExternalId)
                        formData.append('qmAuthorId', this.qmAuthorId)
                        formData.append('qmAuthorName', this.qmAuthorName)
                    }

                    try {
                        await axios.post(process.env.VUE_APP_API_URL + '/message/' + this.contactId + '/' + this
                            .destNumber + '/video',
                            formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                onUploadProgress: function (progressEvent) {
                                    appSubmit.uploadPercentage = parseInt(Math.round((progressEvent.loaded /
                                        progressEvent.total) * 100))
                                }
                            }
                        )

                        this.$emit('send-video-callback-api', null)

                        this.$bvModal.hide('send-video-modal');
                        this.$emit('clearQuoted')
                    } catch (error) {
                        this.$emit('send-video-callback-api', error)
                        this.error = this.$helpers.createError(error)
                    }

                    appSubmit.isSendInProgress = false;
                } else {
                    this.error = {
                        html: "Video masih kosong"
                    }
                }
            },
            uploadFile: async function () {
                var appSubmit = this;
                this.error = {}

                if (!this.$helpers.isBlank(this.file)) {
                    this.isSendInProgress = true;
                    this.uploadPercentage = 0;
                    let formData = new FormData();
                    formData.append('file', this.file);
                    formData.append('caption', this.caption);
                    formData.append('mediaType', 'video');

                    try {
                        await axios.post(process.env.VUE_APP_API_URL + '/attachment',
                            formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                onUploadProgress: function (progressEvent) {
                                    appSubmit.uploadPercentage = parseInt(Math.round((progressEvent.loaded /
                                        progressEvent.total) * 100))
                                }
                            }
                        )

                        this.$bvModal.hide('send-video-modal');
                        this.$emit('clearQuoted')
                    } catch (error) {
                        this.error = this.$helpers.createError(error)
                    }

                    appSubmit.isSendInProgress = false;
                } else {
                    this.error = {
                        html: "Video masih kosong"
                    }
                }
            }
        },
        props: [
            'contactId', 'destNumber', 'sourceChannelId', 'channel',
            'qmOriginId', 'qmText', 'qmOriginExternalId', 'qmAuthorId', 
            'qmAuthorName'
        ],
        components: {
            ErrorMessage
        }
    }
</script>