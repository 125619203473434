module.exports = [{
        title: 'Rincian Retur',
        group: 'retur',
        template: 'pengembalian pembelanjaan nomor <%= item.salesOrder.formalId%> , dengan produk yang dikembalikan yaitu:\n<% item.lines.forEach(e => { %>\n<%= e.product.name%> - <%= e.product.localSKu%> <%= e.qty%> <%= e.qty > 2 ? \'pcs\' : \'pc\'%> dengan alasan pengembalian <%= e.returnReason == \'DID_NOT_FIT\' ? \'ukuran tidak sesuai\' : e.returnReason == \'NOT_SATISFIED\' ? \'produk tidak sesuai\' : e.returnReason == \'DEFECTIVE\' ? \'produk cacat\'  : e.returnReason == \'PRODUCT_PROMO\' ? \'produk promo\' : \'lainnya\'%><% }) %>\n\nDengan status <%= item.returnStatus == \'RETURNED\' ? \'dapat dikembalikan\' : item.returnStatus == \'VOID\' ? \'tidak dapat dikembalikan\' : \'sedang diproses\'%>, dengan alasan <%= item.description%>'
    },
    {
        title: 'Alamat',
        group: 'retur',
        template: 'untuk pesanan yang ingi diretur bisa dikirim ke alamat berikut ya kak\n\nPT. Bina Fajar Estetika (Tuneeca Group Factory) Up. Admin Gudang Barang Jadi (GBJ) Jl. Rancaekek Majalaya RT 001 RW 006, Desa Solokan Jeruk Kecamatan Solokan Jeruk Kabupaten Bandung (Patokan depan GOR PGRI Solokan Jeruk) Phone : (022) 842265\n\njika barangnya sudah kami terima nanti akan kami infokan kembali ya kak, terima kasih :)'
    },
]