module.exports = {
    'SHOPPING_FORMAT': {
        group: 'pembelanjaan',
        title: 'format pembelanjaan',
        template: `Baik kak, Untuk pemesanan bisa melalui WA ini dengan melengkapi data berikut :\n\nNama : \nAlamat Email : \nPhone Number : \n Alamat Pengiriman : \nKode Produk : \nUkuran : `
    },
    'CHECK_TRANSFER_FALSE': {
        group: 'pembelanjaan',
        title: 'jika belum transfer',
        template: `Baik kak, untuk transfernya akan kami cek terlebih dahulu, dan pesanan akan kami kirimkan ke alamat kaka, Jika sudah terima produknya` +
        `jangan lupa untuk foto dan upload fotonya di Instagram dengan Hash tag #TUNEECAOTD. akan ada hadiah menarik jika foto kakak terpilih menjadi` +
        `The Best Outfit Of The Day, Jangan lupa juga untuk download aplikasi Tuneeca di playstore/appstore. Terimakasih telah berbelanja produk kami :)` +
        `\n\nUntuk mengunjungi shopee kami kakak bisa klik link berikut ya https://shopee.co.id/tuneecaofficial`
    },
    'CHECK_TRANSFER_TRUE': {
        group: 'pembelanjaan',
        title: 'jika sudah transfer',
        template: `Halo kak, untuk trfnya sudah kami terima ya kak, pesanan akan segera kami kirimkan ke alamat kakak. Jika sudah terima produknya jangan` +
        `lupa untuk berikan rating dan ulasan produk ya kak, agar kami tau respon kayak terhadap produk kami. Terimakasih telah berbelanja produk kami.` + 
        `Sampai jumpa di pembelanjaan berikutnya ya kak.\n\nJangan sampai terlewat update info produk baru dan promo dari kami, follow serta pantau media` +
        `sosial kami di https://linktr.ee/tuneeca Kak :)`
    },
    'HELP_CUSTOMER': {
        group: 'pembelanjaan',
        title: 'format pembelanjaan',
        template: `Halo kak, ada yang bisa dibantu?`
    },
    'NOTA_CUSTOMER': {
        group: 'pembelanjaan',
        title: 'format pembelanjaan',
        template: `Baik akan kami buatkan nota pemesanannya terlebih dahulu yah ka :)`
    },
    'INFO_CUSTOMER': {
        group: 'pembelanjaan',
        title: 'format pembelanjaan',
        template: `Untuk melihat koleksi lengkap kami, kakak bisa kunjungi www.tuneeca.com. Yuk follow dan pantau terus social media kami di https://linktr.ee/tuneeca ` +
        `untuk mengetahui update info produk terbaru dan promo menarik dari Tuneeca Group.`
    },
    'GREETING_CUSTOMER': {
        group: 'pembelanjaan',
        title: 'format pembelanjaan',
        template: `Halo kak selamat <%= jam > 10 ? 'siang' : jam > 6 ? 'malam' : 'pagi' %> :) Boleh diinfokan kakak tertarik dengan koleksi yang mana kak?`
    },
}