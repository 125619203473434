<template>
    <b-dropdown id="dropdown-1" no-caret @show="searchReturn" title="Pengembalian" class="megaDropdown">
        <template #button-content>
            <i class="fa fa-undo"></i>
        </template>
        <div class="wrapp-chat-template wrapp-order-link wrapp-product-link">
            <div class="heading-history-trans">
                <div class="font-weight-bold m-b-10">
                    Pengembalian
                </div>
                <div class="row retur-filter">
                    <div class="col-6">
                        <label for="" class="text-primary">Tanggal Mulai</label>
                        <input type="date" class="form-control" v-model="startDate">
                    </div>
                    <div class="col-6">
                        <label for="" class="text-primary">Tanggal Selesai</label>
                        <input type="date" class="form-control" v-model="endDate">
                    </div>
                    <div class="col-6 pt-0 mt-0 pb-0 mb-0">
                        <input class="form-control" type="text" placeholder="Nomor Pengembalian" @keyup.enter="searchReturn"
                            v-model="searchTerm" aria-label="Search" />
                    </div>
                    <div class="col-6 pt-0 mt-0 pb-0 mb-0">
                        <input class="form-control" type="text" placeholder="Nomor Penjualan" @keyup.enter="searchReturn"
                            v-model="searchTermOrder" aria-label="Search" />
                    </div>
                </div>
                <button class="btn-cari mt-2 btn btn-primary" @click="searchReturn">Cari</button>
                <div class="clearfix"></div>
            </div>
            <div v-if="isLoading">
                <div class="text-center mt-2">
                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                </div>
            </div>
            <div v-if="returnList <= 0 && !isLoading" class="data-null">
                {{ info }}
            </div>
            <div class="wrapp-order-trans" v-if="returnList.length > 0">
                <div class="head-trans-detail font-weight-bold">
                    <span>Total Pengembalian: Rp {{ formatCurrency(total) }}</span>
                    <button class="btn-sm btn btn-primary pull-right" @click="sendReturnInfo(null, null, false, $event)" disabled>
                        Kirim
                    </button>
                </div>
            </div>
            <div class="wrapp-order-trans" v-for="item in returnList" :key="item.id">
                <div class="head-trans-detail font-weight-bold" v-if="item.returnStatus == 'DRAFT' || item.returnStatus == 'RETURNED'">
                    <input type="checkbox" :id="'check-' + item.formalId" class="pull-left mt-1 mr-2" :name="'check-' + item.formalId"
                        @input="calculateAmount(item, $event)" disabled>
                    <span class="">
                        <b>#{{item.formalId}} | {{item.salesOrder.formalId}}</b>
                    </span>
                    <b-dropdown id="dropdown-12" size="sm" text="Kirim" class="pull-right send-button" variant="primary">
                        <b-dropdown-item style="width:300px" class="" v-for="(order, index) in quickReturn" :key="index">
                            <div class="row">
                                <div class="col-md-9">
                                    {{order.title}}
                                </div>
                                <div class="col-md-2">
                                    <a class="btn btn-primary btn-sm" @click="sendReturnInfo(item, order.template, false, $event)">Kirim</a>
                                </div>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                    <div class="clearfix"></div>
                    <small class="text-muted">{{ item.creationTime }} | Rp {{ formatCurrency(item.totalAmount) }}</small>
                    <div class="clearfix m-t-10"></div>
                    <small class="light m-r-10 text-muted font-weight-normal">
                        <i class="fa fa-undo m-r-5"></i> {{ item.returnStatus }}
                    </small>
                </div>
            </div>
        </div>
    </b-dropdown>
</template>
<script>
import axios from 'axios'

const template = require('lodash.template');
var moment = require('moment');

import RETURN from '../utils/templates/retur'

export default {
    props: ['parentId', 'contactId'],

    components: {},

    data: function() {
        return {
            total: 0,
            currentPage: 1,
            info: 'Belum ada pengembalian',
            
            startDate: String(),
            endDate: String(),
            selectedStartDate: String(),
            selectedEndDate: String(),
            searchTerm: String(),
            searchTermOrder: String(),

            error: Object(),
            
            returnList: Array(),
            checkedReturn: Array(),
            quickReturn: Array(),
            
            hasMoreDataNext: true,
            isLoading: false,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
            },
        }
    },

    methods: {
        calculateAmount: function (item, event) {
            event.preventDefault()

            if (document.getElementById(`check-${item.formalId}`).checked) {
                this.total = this.total + item.totalAmount
                this.checkedReturn = [...this.salesOrderUiFlag.checkedReturn, {
                    formalId: item.formalId,
                    totalAmount: item.totalAmount
                }]
            } else {
                this.total = this.total - item.totalAmount
                const filtered = this.checkedReturn.filter(e => e.formalId != item.formalId)
                this.checkedReturn = filtered
            }

        },
        searchReturn: async function () {
            this.isLoading = true
            try {
                const res = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/cust-returns', {
                    params: {
                        formalId: this.searchTerm,
                        salesOrderFormalId: this.searchTermOrder,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        customerId: this.parentId ? this.parentId : this.contactId,
                        page: 0,
                        size: 100,
                        sortField: 'modificationTime',
                        sortOrder: 'DESCENDING'
                    }
                })

                let tmp = res.data.content.map(data => {
                    data.creationTime = moment(data.creationTime).format('DD MMMM YYYY')

                    return data
                })

                this.returnList = tmp
                if (tmp.length < 1) this.info = 'Pengembalian tidak ada'
            } catch (error) {
                console.log("error search sales order", error);
            }
            this.isLoading = false
        },
        sendReturnInfo: async function (item, templates, send, event) {
            event.preventDefault();

            try {
                const str = template(templates)({
                    'item': item
                })

                if (send) {
                    this.$emit('send-info-return-order', str)
                } else {
                    this.$emit('copy-info-return-order', str)
                }

            } catch (error) {
                console.log("send-info", error);
            }
        },
        formatCurrency(price) {
            let val = (price / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
    },

    watch: {},

    mounted: function() {
        this.quickReturn = RETURN
    },
}

</script>
