import { render, staticRenderFns } from "./DropdownProduct.vue?vue&type=template&id=651c1fdc"
import script from "./DropdownProduct.vue?vue&type=script&lang=js"
export * from "./DropdownProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports