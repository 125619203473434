<template>
    <v-dialog v-model="dialogForward" scrollable max-width="450" persistent>
        <v-card class="mx-auto">
            <v-toolbar color="deep-purple accent-4" dark>
                <v-btn icon @click="closeDialog('CANCEL')" :disabled="isLoadingForward">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title class="text-white">Teruskan Pesan</v-toolbar-title>
            </v-toolbar>
            <div class="mx-4">
                <v-row>
                    <v-col cols="9">
                        <v-text-field v-model="term" @keydown.enter="getContacts"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn class="mt-4" @click="getContacts" :loading="isLoading" :disabled="isLoading">
                            Cari
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mt-0" v-if="selectedContactsForward.length > 0">
                    <v-col cols="9">
                        <v-select label="Pilih Kanal"
                            v-model="selectedChannel"
                            :items="sessionInfo.activeChannels"
                            item-value="_id"
                            item-text="name"></v-select>
                    </v-col>
                    <v-col>
                        <v-btn color="deep-purple accent-4" icon fab dark 
                            @click="forwardMessage" 
                            :disabled="!selectedChannel || isLoadingForward"
                            :loading="isLoadingForward"
                        >
                            <v-icon dark>mdi-send</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <div class="list-user-container">
            <v-list>
                <v-list-item v-for="(item, index) in contactList" :key="item.id + index">
                    <v-checkbox v-model="selectedContactsForward" :value="item" :disabled="isLoading"></v-checkbox>
                    <div class="wrapp-img mr-4">
                        <span>
                            <div class="initials" v-if="item.name && item.name.includes('+')">
                                <i class="mdi" :class="{ 
                                        'mdi-account-multiple': item.isGroupContact == true || item.isGroupContact == 'true',
                                        'mdi-account': item.isGroupContact == false || item.isGroupContact == 'false' || !item.isGroupContact,
                                    }"></i>
                            </div>
                            <div class="initials" v-if="item.name && !item.name.includes('+')">
                                {{ changeInitialName(item.name) }}
                            </div>
                        </span>
                        <span class="is-affiliator" v-if="item.isAffiliator" title="Afiliator">
                            <i class="text-affiliator mdi mdi-link-variant "></i>
                        </span>
                    </div>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.primaryPhoneNumber }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <div v-if="item.isLoading">
                        <v-progress-circular v-if="!item.sendStatus"
                            indeterminate
                            color="purple"
                        ></v-progress-circular>
                    </div>
                    <div v-else>
                        <v-icon color="success" v-if="item.sendStatus == 'DONE'">
                            mdi-checkbox-marked-circle
                        </v-icon>
                    </div>
                </v-list-item>
            </v-list>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import axios from "axios";
    import { mapState } from 'vuex'

    const uuidv4 = require('uuid/v4');

    export default {
        props: ['dialogForward', 'selectedMessagesForward', 'selectedSourceChannelId'],

        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            }),
        },

        data: function() {
            return {
                selectedStatus: "ACTIVE",

                term: String(),
                selectedRole: String(),
                selectedChannel: String(),

                contactList: Array(),
                selectedContactsForward: Array(),

                isLoading: false,
                isLoadingForward: false,
            }
        },

        watch: {
            selectedSourceChannelId: function(val) {
                if(val) this.selectedChannel = val
            }
        },

        methods: {
            forwardMessage: async function () {
                const contacts = [
                    ...this.selectedContactsForward.map(e => {
                        e.isLoading = true
                        e.sendStatus = null

                        return e
                    })
                ]
                const messages = [...this.selectedMessagesForward].sort((a, b) => a.index - b.index)

                this.contactList = contacts
                this.isLoadingForward = true

                for (let i = 0; i < contacts.length; i++) {
                    const contact = contacts[i];
                    
                    for (let j = 0; j < messages.length; j++) {
                        const msg = messages[j];
                        
                        let postData = {
                            "id": uuidv4(),
                            "channelId": this.selectedChannel,
                            "text": msg.text,
                            "isForward": true,
                            "number": contact.primaryPhoneNumber
                        };

                        // if (contact.communicatorDefaultChannel == 'FB') {
                        //     postData.contactFbId = contact.primaryPhoneNumber
                        // } else if (contact.communicatorDefaultChannel == 'SMS' || contact.communicatorDefaultChannel == 'WA') {
                        //     postData.number = contact.primaryPhoneNumber
                        // }

                        try {
                            if(msg.contentType == 'text') {
                                await axios.post(process.env.VUE_APP_API_URL + '/message/' + contact.id + '/' + contact.communicatorDefaultChannel, postData);
                            } else if(msg.attachmentId) {
                                await axios.post(process.env.VUE_APP_API_URL + '/attachment/' + contact.id + '/' + 
                                    contact.communicatorDefaultChannel + '/' + msg.attachmentId, postData);
                            } else if(msg.fileSize) {
                                let formData = new FormData();
                                formData.append('caption', msg.text);
                                formData.append('id', uuidv4());
                                formData.append('channel', contact.communicatorDefaultChannel);
                                formData.append('channelId', this.selectedChannel);
                                formData.append('fileName', msg.fileName);
                                formData.append('fileSize', msg.fileSize);
                                formData.append('url', msg.url);
                                formData.append('isForward', true);
                                formData.append('number', contact.primaryPhoneNumber);

                                // if (contact.communicatorDefaultChannel == 'FB') {
                                //     formData.append('contactFbId', contact.primaryPhoneNumber);
                                // } else if (contact.communicatorDefaultChannel == 'SMS' || contact.communicatorDefaultChannel == 'WA') {
                                //     formData.append('number', contact.primaryPhoneNumber);
                                // }

                                await axios.post(process.env.VUE_APP_API_URL + '/message/' + contact.id + '/' 
                                    + contact.primaryPhoneNumber + '/' + msg.contentType, formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    }
                                )
                            } else {
                                postData.url = msg.url

                                await axios.post(process.env.VUE_APP_API_URL + '/message/' + contact.id + '/' + 
                                    contact.communicatorDefaultChannel + '/' + msg.contentType + '-url', postData)
                            }
                        } catch (error) {
                            console.log("error-forward-message", error.message);
                        }

                        contact.isLoading = false
                        contact.sendStatus = 'DONE'

                        contacts.splice(i, 1, contact)
                    }
                }
                this.isLoadingForward = false
                this.closeDialog('DONE')
            },
            getContacts: async function() {
                try {
                    this.isLoading = true

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/contact', {
                        params: {
                            page: 1,
                            size: 25,
                            term: this.term,
                            status: this.selectedStatus,
                            role: this.selectedRole,
                        }

                    });

                    const filter = data.content.filter(e => {
                        const index = this.selectedContactsForward.findIndex(el => el.id == e.id)

                        return index < 0
                    })

                    const selected = this.contactList.filter(e => {
                        const index = this.selectedContactsForward.findIndex(el => el.id == e.id)

                        return index >= 0
                    })
                    
                    this.contactList = [...selected, ...filter]

                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false
                    console.log("contacts", error.message)
                }
            },
            closeDialog: function(kind) {
                this.$emit('closeDialog', kind)

                this.term = String()
                this.selectedStatus = "ACTIVE"
                this.selectedRole = String()

                this.contactList = Array()
                this.selectedContactsForward = Array()
            },
            changeInitialName: function (name) {
                let changedName = String()

                name.trim().split(" ").forEach(e => {
                    if (changedName.length < 2) changedName += e[0]
                })

                return changedName
            },
        }
    }
</script>