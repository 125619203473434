<template>
    <b-dropdown id="dropdown-1" @show="getColors('')" no-caret title="Link Produk" class="megaDropdown">
        <template #button-content>
            <i class="mdi mdi-cube" title="Produk"></i>
        </template>

        <div class="wrapp-chat-template wrapp-order-link">
            <div class="heading-history-trans">
                <div class="font-weight-bold m-b-10">
                    Daftar Produk
                    <div class="float-right history-trans">
                        <b-dropdown no-caret id="dropdown-12" size="sm" class="mr-2 medium" title="Warna" variant="">
                            <template #button-content>
                                <v-icon>
                                    mdi-invert-colors
                                </v-icon>
                            </template>

                            <div class="select-size select-color mr-3">
                                <v-text-field class="select-color"
                                    v-model="searchColor"
                                    type="text"
                                    label="Warna" placeholder="Contoh: red"/>
                                <div class="text-center mt-2" v-if="isLoadingColor">
                                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                </div>
                                <div v-for="color in colors" :key="color.id" class="mb-4 list-color" @click="selectedColor = color">
                                    <span class="box-color box mr-2 pull-left" :style="{ 'background-color': color.color }"></span>
                                    <span>{{ color.name }}</span>
                                </div>
                            </div>
                        </b-dropdown>
                        <b-dropdown no-caret id="dropdown-12" size="sm" class="mr-2 medium" title="Harga" variant="">
                            <template #button-content>
                                <i class="fa fa-money"></i>
                            </template>

                            <div class="select-size mr-3">
                                <v-text-field class="select-color" v-model="minPrice"
                                    type="number"
                                    label="Min Price"/>
                                <v-text-field class="select-color" v-model="maxPrice"
                                    type="number"
                                    label="Max Price"/>
                            </div>
                        </b-dropdown>
                        <span class="mr-2 send-button">
                            <v-icon v-if="sortDir" @click="sortDir = !sortDir" title="Urutkan Berdasarkan Harga Terkecil">
                                mdi-arrow-up
                            </v-icon>
                            <v-icon v-else @click="sortDir = !sortDir" title="Urutkan Berdasarkan Harga Terbesar">
                                mdi-arrow-down
                            </v-icon>
                        </span>
                        <b-dropdown no-caret id="dropdown-12" size="sm" class="mr-2 send-button" title="Variant" variant="">
                            <template #button-content>
                                <v-icon>
                                    mdi-tag
                                </v-icon>
                            </template>

                            <div class="select-size">
                                <v-checkbox v-model="selectedVariants"
                                    label="XS" value="XS"></v-checkbox>
                                <v-checkbox v-model="selectedVariants"
                                    label="S" value="S"></v-checkbox>
                                <v-checkbox v-model="selectedVariants"
                                    label="S+" value="S+"></v-checkbox>
                                <v-checkbox v-model="selectedVariants"
                                    label="M" value="M"></v-checkbox>
                                <v-checkbox v-model="selectedVariants"
                                    label="M-" value="M-"></v-checkbox>
                                <v-checkbox v-model="selectedVariants"
                                    label="L" value="L"></v-checkbox>
                                <v-checkbox v-model="selectedVariants"
                                    label="XL" value="XL"></v-checkbox>
                            </div>
                        </b-dropdown>
                    </div>
                </div>
                <div class="mt-4 mb-2">
                    <input style="width:80%" class="pull-left form-control" type="text" placeholder="Search" v-model="searchTerm"
                        @keyup.enter="searchProducts" aria-label="Search" />
                    <button class="ml-2 btn-cari btn btn-primary" @click="searchProducts">Cari</button>
                    <div class="clearfix"></div>
                </div>
                <div v-if="selectedColor" class="mt-2">
                    <span class="box pull-left box-color mr-2" :style="{'background-color': selectedColor.color }"></span>
                    <span>
                        {{ selectedColor.name }}
                    </span>
                    <v-icon class="list-color" @click.native.capture.stop="selectedColor = null">
                        mdi-close
                    </v-icon>
                </div>
                <div v-if="minPrice > 0" title="Minimal Harga" class="badge badge-secondary badge-pill mr-2 mt-2">
                    <v-icon style="font-size:12px !important" class="text-white">
                        mdi-cash
                    </v-icon>
                    Rp {{ formatCurrency(minPrice) }}
                    <v-icon style="font-size:12px !important" class="text-white list-color" right @click.native.capture.stop="minPrice = 0">
                        mdi-close
                    </v-icon>
                </div>

                <div v-if="maxPrice > 0" title="Maksimal Harga" class="badge badge-secondary badge-pill mr-2 ">
                    <v-icon style="font-size:12px !important" class="text-white">
                        mdi-cash
                    </v-icon>
                    Rp {{ formatCurrency(maxPrice) }}
                    <v-icon style="font-size:12px !important" class="text-white list-color" right @click.native.capture.stop="maxPrice = 0">
                        mdi-close
                    </v-icon>
                </div>
                <div v-if="selectedVariants.length > 0" class="badge badge-secondary badge-pill mr-2 mt-2 ">
                    <v-icon style="font-size:12px !important" class="text-white" left>
                        mdi-tag
                    </v-icon>
                    {{ selectedVariants.join(', ') }}
                    <v-icon style="font-size:12px !important" class="text-white list-color" right @click.native.capture.stop="selectedVariants = []">
                        mdi-close
                    </v-icon>
                </div>
                <div class="clearfix"></div>
                <div class="show-hidden-prod">
                    <input class="mt-1 pull-left" type="checkbox" id="checkbox-1" v-model="hidden" name="checkbox-1">
                    <label class="ml-2" for="checkbox-1">Tampilkan produk yang disembunyikan</label>
                </div>
            </div>
            <div v-if="isLoading">
                <div class="text-center mt-2">
                    <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                </div>
            </div>
            <div v-if="productList.length <= 0 && !isLoading" class="data-null">
                {{ info }}
            </div>

            <div v-if="!isLoading">
                <div v-for="(item, dex) in productList" :key="item.id"  style="padding: 8px 1rem;">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="wrapp-img-link" v-if="item.displayImages">
                                <span v-if="item.displayImages.length > 0">
                                    <img :src="item.displayImages[0].styles.thumbnail.src" alt="user-img" class="img-responsive">
                                </span>
                            </div>
                        </div>
                        <div class="col-md-9">
                           
                            <span class="product-title-link font-weight-bold">{{ item.name }}</span>
                            <span class="product-title-link">{{ item.localSku }}</span>
                            <div class="clearfix m-t-5">
                                <span v-if="item.price != item.finalPrice">
                                    <s>Rp {{ formatCurrency(item.price) }}</s> | Rp {{ formatCurrency(item.finalPrice) }}
                                </span>
                                <span v-else>Rp {{ formatCurrency(item.price) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" v-if="item.isLoading">
                            <div class="text-center ml-2 mt-2">
                                <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-md-12" v-if="!item.isLoading">
                            <div class="row" v-for="(kk, index) in item.other"
                                :key="index">
                                <div class="col-md-12 pb-2" v-if="kk.color != 'no_color'">{{ kk.color }}</div>
                                <div class="col-md-12 pt-0">
                                    <ul class="list-group list-group-horizontal-sm">
                                        <li class="list-group-item" v-for="e in kk.list"
                                            :key="e.id">
                                            <small class="text-center">
                                                {{ e.optionProperties && e.optionProperties.base_size ? e.optionProperties.base_size[0].displayValue : '' }}
                                                <div class="clearfix"></div>
                                                <div class="clearfix"></div>
                                                {{ 1 > item.qty ? '0' : e.qty > 3 ? '3+' : e.qty }}
                                                <span></span>
                                            </small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-2">
                            <b-dropdown id="dropdown-11" size="sm" text="Kirim" class="send-button" variant="primary">
                                <b-dropdown-item @click="sendProductImageUrlMessage(item, $event)">Gambar</b-dropdown-item>
                                <b-dropdown-item @click="sendProductsImageUrlMessage(item, $event)">Semua Gambar</b-dropdown-item>
                                <b-dropdown-item @click="dialogLover = true, getProductLover(item.id, $event), selectedLoverProduct = item">Gambar Lovers</b-dropdown-item>
                                <b-dropdown-item @click="sendProductSizeGuideUrlMessage(item, $event)">Panduan Ukuran</b-dropdown-item>
                                <b-dropdown-item @click="dialogReview = true, getProductReview(item.id, $event), selectedLoverProduct = item">Review</b-dropdown-item>
                                <span v-for="(order, indexx) in quickProduct" :key="indexx">
                                    <b-dropdown-item v-if="!item.embedVideo && order.title != 'Video'" 
                                        @click="sendInfoProductMessage({...item}, order.template, order.title, $event)"
                                    >
                                        {{ order.title }}
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="item.embedVideo"
                                        @click="sendInfoProductMessage({...item}, order.template, order.title, $event)"
                                    >
                                        {{ order.title }}
                                    </b-dropdown-item>
                                </span>
                            </b-dropdown>
                            <a class="ms-2 btn btn btn-default btn-sm" @click="refreshStock(item, dex)">Sinkronkan Stok</a>
                            <a class="ms-2 btn btn btn-default btn-sm" @click="showOrderModal(item, dex)">Buat Pesanan</a>
                        </div>
                    </div>
                    <hr style="margin:0.5rem 0">
                </div>
            </div>
        </div>
        <v-dialog v-model="dialogReview" scrollable max-width="600" persistent>
            <v-card>
                <v-toolbar color="deep-purple accent-4" dark>
                    <span class="text-h6 text-white">
                        Kirim Review
                    </span>
                </v-toolbar>
                <div class="text-center mt-5" v-if="isLoadingGetProductLover">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <v-card-text class="mt-5" v-if="!isLoadingGetProductLover">
                    <div class="text-h4 pa-12" v-if="reviews.length < 1">
                        Data tidak ditemukan!
                    </div>
                    <v-row v-for="(item, index) in reviews" :key="index + item.id">
                        <v-col cols="4">
                            <div>
                                <v-rating half-increments readonly
                                    background-color="orange lighten-3" 
                                    color="orange" 
                                    size="25"
                                    length="5"
                                    :value="item.summaryRating / 2"
                                ></v-rating>
                            </div>
                            <span class="ml-2">
                                <b>Oleh {{ item.reviewer.name }}</b>
                            </span>
                        </v-col>
                        <v-col cols="8">
                            <div class="mb-5">
                                <span><b>{{ item.title }}</b></span>
                                <br />
                                <i>"{{ item.description }}"</i>
                            </div>
                            <v-row v-if="item.imageReview.length > 0">
                                <v-col cols="4" v-for="imgr in item.imageReview" :key="imgr.id" 
                                    :style="{ 'border': selectedImageReview.id == imgr.id ? '2px solid blue' : '' }">
                                    <a @click="selectedImageReview = {...imgr, description: item.description}">
                                        <v-img :src="imgr.styles.small.uri" alt="review" aspect-ratio="1" />
                                    </a>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="4" :style="{ 'border': selectedImageReview.id == item.id ? '2px solid blue' : '' }">
                                    <a @click="selectedImageReview = { id: item.id, description: item.description }">
                                        <v-img :src="imgNotFound" alt="review" aspect-ratio="1" />
                                    </a>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="dialogReview = false" :disabled="isLoadingSendImageLover">
                        Kembali
                    </v-btn>
                    <v-btn class="btn btn-primary" @click="sendReviewLovers(false)" v-if="!isLoadingGetProductLover"
                        :disabled="Object.keys(selectedImageReview).length < 1 || isLoadingSendImageLover" :loading="isLoadingSendImageLover">
                        Kirim
                    </v-btn>
                    <v-btn class="btn btn-primary" @click="sendReviewLovers(true)" v-if="!isLoadingGetProductLover"
                        :disabled="Object.keys(selectedImageReview).length < 1 || isLoadingSendImageLover" :loading="isLoadingSendImageLover">
                        Kirim Dengan Template
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogLover" scrollable max-width="600" persistent>
            <v-card>
                <v-toolbar color="deep-purple accent-4" dark>
                    <span class="text-h6 text-white">
                        Kirim Gambar Lovers
                    </span>
                </v-toolbar>
                <div class="text-center mt-5" v-if="isLoadingGetProductLover">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <v-card-text class="mt-5" v-if="!isLoadingGetProductLover">
                    <div class="text-h4 pa-12" v-if="lovers.length < 1">
                        Data tidak ditemukan!
                    </div>
                    <v-row v-for="(item, index) in lovers" :key="index + item.id">
                        <v-col cols="1">
                            <v-checkbox v-model="selectedLovers" :value="item"></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                            <span>{{ item.customerName }}</span>
                            <br />
                            <span>{{ item.customerId }}</span>
                        </v-col>
                        <v-col cols="8">
                            <v-img :src="item.image.styles.normal.uri"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="dialogLover = false" :disabled="isLoadingSendImageLover">
                        Kembali
                    </v-btn>
                    <v-btn class="btn btn-primary" @click="sendImageLovers(false)" v-if="!isLoadingGetProductLover"
                        :disabled="selectedLovers.length < 1 || isLoadingSendImageLover" :loading="isLoadingSendImageLover">
                        Kirim
                    </v-btn>
                    <v-btn class="btn btn-primary" @click="sendImageLovers(true)" v-if="!isLoadingGetProductLover"
                        :disabled="selectedLovers.length < 1 || isLoadingSendImageLover" :loading="isLoadingSendImageLover">
                        Kirim Dengan Template
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogOrder" scrollable max-width="600" persistent>
            <v-card>
                <v-toolbar color="deep-purple accent-4" dark>
                    <span class="text-h6 text-white">Buat Pesanan</span>
                </v-toolbar>
                <v-card-text class="m-t-20">
                    <div>
                        <v-row>
                            <v-col cols="3">
                                Nama Konsumen :
                            </v-col>
                            <v-col cols="9">
                                {{ contactId }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea label="Note" rows="2" v-model="note"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-if="this.selectedProduct.type == 'VARIED' && selectedProduct.other.length < 1">
                            <v-col>
                                <h2>Qty Produk Tidak Tersedia</h2>
                            </v-col>
                        </v-row>
                        <div v-if="this.selectedProduct.type == 'VARIED'">
                            <v-row class="mt-0" v-for="(kk, index) in selectedProduct.other" :key="index">
                                <v-col cols="3">
                                    {{ kk.color != 'no_color' ? kk.color : 'Pilih Ukuran' }} :
                                </v-col>
                                <v-col style="display: flex;">
                                    <span v-for="(lor, idx) in kk.list" :key="idx" class="mr-3">
                                        <v-checkbox :disabled="lor.qty < 1"
                                            v-model="selectedVariantsModal"
                                            :value="lor.id"
                                            :label="displaySize(lor)" />
                                    </span>
                                    <!-- <v-autocomplete clearable deletable-chips dense filled multiple rounded small-chips return-object
                                        :label="kk.color != 'no_color' ? kk.color : 'Pilih Ukuran'"
                                        :items="kk.list"
                                        :item-text="displaySize"
                                        v-model="kk.selected"
                                    ></v-autocomplete> -->
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end mb-2">
                    <v-btn text @click="dialogOrder = false">
                        Kembali
                    </v-btn>
                    <v-btn class="btn btn-primary" @click="saveOrder()" :loading="isLoadingCreateOrder"
                        :disabled="this.selectedProduct.type == 'VARIED' && selectedProduct.other.length < 1">
                        Simpan
                    </v-btn>
                    <br>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </b-dropdown>
</template>
<script>
import axios from 'axios'
import Toast from '../plugins/vuenotification'

import { mapState } from 'vuex'

const uuidv4 = require('uuid/v4');
const template = require('lodash.template');

import PRODUCT from '../utils/templates/product'
import IMAGE_MESSAGE from '../../public/images/image-not-found.png'
import { devServer } from '../../vue.config';

const { toFriendlyDateTime } = require('../utils/date-utils');

export default {
    props: ['channelId', 'channelDefault', 'contactId', 'contactNumber'],

    components: {},

    data: function() {
        return {
            imgNotFound: IMAGE_MESSAGE,
            currentPage: 0,
            minPrice: 0,
            maxPrice: 0,
            info: 'Belum ada produk',
            
            searchTerm: String(),
            searchColor: String(),
            note: String(),
            
            selectedProduct: Object(),
            selectedLoverProduct: Object(),
            selectedImageReview: Object(),
            error: Object(),
            
            selectedVariantsModal: Array(),
            selectedVariants: Array(),
            quickProduct: Array(),
            productList: Array(),
            colors: Array(),
            lovers: Array(),
            selectedLovers: Array(),
            reviews: Array(),
            
            hasMoreDataNext: true,
            hidden: false,
            isLoading: false,
            sortDir: true,
            isLoadingColor: false,
            isLoadingCreateOrder: false,
            isLoadingGetProductLover: false,
            isLoadingSendImageLover: false,
            dialogOrder: false,
            dialogLover: false,
            dialogReview: false,

            selectedColor: null,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
            },
        }
    },

    computed: {
        ...mapState({
            commerce: state => state.session.commerce
        }),
    },

    methods: {
        showOrderModal: async function (item, index) {
            await this.refreshStock(item, index)
            this.selectedProduct = {...this.productList[index]}
            this.dialogOrder = true
        },
        hideOrderModal: function () {
            this.selectedProduct = Object()
            this.dialogOrder = false
        },
        displaySize: function (item) {
            return item.optionProperties && item.optionProperties.base_size ? item.optionProperties.base_size[0].displayValue : 'Lainnya'
        },
        saveOrder: async function () {
            try {
                this.isLoadingCreateOrder = true

                const body = {
                    customerId: this.contactId,
                    note: this.note,
                    tag: "bippotalk",
                    lines: Array()
                }

                if(this.selectedProduct.type == 'SIMPLE') {
                    body.lines.push({
                        "productId": this.selectedProduct.id,
                        "variantId": null,
                        "qty": 1
                    })
                } else {
                    this.selectedVariantsModal.forEach(e => {
                        body.lines.push({
                            "productId": this.selectedProduct.id,
                            "variantId": e,
                            "qty": 1
                        })
                    })
                }

                const { data } = await axios.post(process.env.VUE_APP_API_URL + '/cmrc/create-order', body)

                this.isLoadingCreateOrder = false
                window.open(this.commerce.webUrl + '/admin/#/edit-sales/invoice=' + data.formalId);
                this.dialogOrder = false
            } catch (error) {
                this.isLoadingCreateOrder = false
                
                if(error.response) {
                    Toast.showToast("error", error.response.data.message)
                } else {
                    Toast.showToast("error", error.message)
                }
            }
        },
        getProductReview: async function(id, event) {
            event.preventDefault();

            try {
                this.isLoadingGetProductLover = true
                const { data } = await axios.get(process.env.VUE_APP_COMMERCE_API_URL + '/review/' + id + '/product?page=0&size=10') 

                this.reviews = data.content

                this.isLoadingGetProductLover = false
            } catch (error) {
                this.isLoadingGetProductLover = false
                console.log("lover", error)
            }
        },
        getProductLover: async function(id, event) {
            event.preventDefault();

            try {
                this.isLoadingGetProductLover = true
                const { data } = await axios.get(process.env.VUE_APP_COMMERCE_API_URL + '/ig-lover/product/' + id + '/') 

                this.lovers = data.content

                this.isLoadingGetProductLover = false
            } catch (error) {
                this.isLoadingGetProductLover = false
                console.log("lover", error)
            }
        },
        getProductDetail: async function (id) {
            try {
                const { data } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/product/' + id)

                return data
            } catch (error) {
                console.log(error);
            }
        },
        sendImageUrl: async function(url, text) {

            const postData = {
                id: uuidv4(),
                channelId: this.channelId,
                text: text,
                url: url,
                number: this.contactNumber
            }

            const createdOn = (new Date()).getTime();

            var msgData = {
                _id: postData.id,
                channel: this.channelDefault,
                contactId: this.contactId,
                createdOn: createdOn,
                contentType: 'image',
                time: toFriendlyDateTime(createdOn),
                from: 'op',
                text: postData.text,
                sentTo: postData.number,
                status: 'pending',
                operatorId: global.userId,
                operatorName: global.userFullName,
                url: postData.url
            }

            this.$emit('product-callback', msgData)

            const { data } = await axios.post(process.env.VUE_APP_API_URL + '/message/' + this.contactId + '/'+ this.channelDefault +'/image-url', postData)

            this.$emit('product-callback-api', msgData, data.externalId, null)
        },
        sendReviewLovers: async function(isTemplate) {
            try {
                const self = this
                this.isLoadingSendImageLover = true

                if(isTemplate) {
                    const text = 'Kak, sebagai referensi berikut kami kirimkan review produk dari Lovers yang lain. Semoga membantu yaa Kak🙂'
    
                    await this.$emit('send-info-product', text, null, null)
                }

                const text = this.selectedLoverProduct.localSku + '\n\n```"' + this.selectedImageReview.description + '"```' +
                    '\n\n' + this.commerce.webUrl + '/id/' + this.selectedLoverProduct.slug
                const textElse = this.selectedLoverProduct.localSku + '\n\n' + this.commerce.webUrl + '/id/' + this.selectedLoverProduct.slug
                    
                if(this.selectedImageReview.styles) {
                    const uri = this.selectedImageReview.styles.normal.uri

                    setTimeout(async () => {
                        if(self.channelDefault == 'WA') {
                            await self.sendImageUrl(uri, self.selectedImageReview.description != "" || self.selectedImageReview.description != null ? text : textElse)
                        } else {
                            await self.sendImageUrl(uri, "")
                            await self.$emit('send-info-product', self.selectedImageReview.description != "" || self.selectedImageReview.description != null ? text : textElse)
                        }
                    }, 500)
                } else {
                    await this.$emit('send-info-product', this.selectedImageReview.description != "" || self.selectedImageReview.description != null ? text : textElse, null, null)
                }
                
                this.isLoadingSendImageLover = false
                this.dialogReview = false

            } catch (error) {
                this.isLoadingSendImageLover = false
                this.$emit('product-callback-api', null, null, error)
                console.log("send-review-lover", error);
            }
        },
        sendImageLovers: async function(isTemplate) {
            try {
                const self = this
                this.isLoadingSendImageLover = true

                if(isTemplate) {
                    const text = 'Kak, sebagai referensi berikut kami kirimkan foto produk dari Lovers yang lain. Semoga membantu yaa Kak🙂'
    
                    await this.$emit('send-info-product', text, null, null)
                }

                for (let i = 0; i < this.selectedLovers.length; i++) {
                    const e = this.selectedLovers[i];

                    setTimeout(async () => {
                        await self.sendImageUrl(e.image.styles.normal.uri, self.selectedLoverProduct.localSku)
                    }, 500)

                }

                this.isLoadingSendImageLover = false
                this.dialogLover = false

            } catch (error) {
                this.isLoadingSendImageLover = false
                this.$emit('product-callback-api', null, null, error)
                console.log("send-lover", error);
            }
        },
        sendProductImageUrlMessage: async function (item, event) {
            event.preventDefault();

            const product = await this.getProductDetail(item.id)
            const imagePrimary = product.displayImages.find(e => e.id == product.sideImageId)
            let imageUrl = String()

            if(imagePrimary) {
                imageUrl = imagePrimary.styles.large.src

                const splited = imageUrl.split('.')
                const extension = splited[splited.length - 1]

                if(extension == 'webp' && this.channelDefault == 'IG') {
                    imageUrl = devServer.proxy + process.env.VUE_APP_API_URL + '/image/convert?eid=' + btoa(imageUrl)
                }
            }

            const postData = {
                id: uuidv4(),
                channelId: this.channelId,
                text: product.localSku,
                url: imageUrl,
                number: this.contactNumber
            }

            const createdOn = (new Date()).getTime();

            var msgData = {
                _id: postData.id,
                channel: this.channelDefault,
                contactId: this.contactId,
                createdOn: createdOn,
                contentType: 'image',
                time: toFriendlyDateTime(createdOn),
                from: 'op',
                text: postData.text,
                sentTo: postData.number,
                status: 'pending',
                operatorId: global.userId,
                operatorName: global.userFullName,
                url: postData.url
            }

            this.$emit('product-callback', msgData)

            try {
                const { data } = await axios.post(process.env.VUE_APP_API_URL + '/message/' + this.contactId + '/'+ this.channelDefault +'/image-url', postData)

                this.$emit('product-callback-api', msgData, data.externalId, null)

            } catch (error) {
                this.$emit('product-callback-api', null, null, error)
                console.log("send-product-image", error);
            }
        },
        sendProductsImageUrlMessage: async function (item, event) {
            event.preventDefault();

            const product = await this.getProductDetail(item.id)

            product.displayImages.forEach(async e => {
                const postData = {
                    id: uuidv4(),
                    channelId: this.channelId,
                    text: product.localSku,
                    url: e.styles.large.src,
                    number: this.contactNumber
                }

                if(postData.url) {
                    const splited = postData.url.split('.')
                    const extension = splited[splited.length - 1]

                    if(extension == 'webp' && this.channelDefault == 'IG') postData.url = devServer.proxy + process.env.VUE_APP_API_URL + '/image/convert?eid=' + btoa(postData.url)
                }

                const createdOn = (new Date()).getTime();

                var msgData = {
                    _id: postData.id,
                    channel: this.channelDefault,
                    contactId: this.contactId,
                    createdOn: createdOn,
                    contentType: 'image',
                    time: toFriendlyDateTime(createdOn),
                    from: 'op',
                    text: postData.text,
                    sentTo: postData.number,
                    status: 'pending',
                    operatorId: global.userId,
                    operatorName: global.userFullName,
                    url: postData.url
                }

                this.$emit('product-callback', msgData)

                try {
                    const { data } = await axios.post(process.env.VUE_APP_API_URL + '/message/' + this.contactId + '/'+ this.channelDefault +'/image-url', postData)

                    this.$emit('product-callback-api', msgData, data.externalId, null)

                } catch (error) {
                    this.$emit('product-callback-api', null, null, error)
                    console.log("send-product-images", error);
                }
            })
        },
        sendProductSizeGuideUrlMessage: async function (item, event) {
            event.preventDefault();

            try {
                const product = await this.getProductDetail(item.id)

                if(!product.additionalProperties) throw new Error('Size Guide Tidak ditemukan')

                const sizeGuide = product.additionalProperties.find(e => e.id == 'sizeGuide')

                if(!sizeGuide || sizeGuide && !sizeGuide.values[0].primaryUri) throw new Error('Size Guide Tidak ditemukan')

                const postData = {
                    id: uuidv4(),
                    channelId: this.channelId,
                    text: product.localSku,
                    url: sizeGuide.values[0].primaryUri,
                    number: this.contactNumber
                }

                const createdOn = (new Date()).getTime();

                var msgData = {
                    _id: postData.id,
                    channel: this.channelDefault,
                    contactId: this.contactId,
                    createdOn: createdOn,
                    contentType: 'image',
                    time: toFriendlyDateTime(createdOn),
                    from: 'op',
                    text: postData.text,
                    sentTo: postData.number,
                    status: 'pending',
                    operatorId: global.userId,
                    operatorName: global.userFullName,
                    url: postData.url
                }

                this.$emit('product-callback', msgData)

                const { data } = await axios.post(process.env.VUE_APP_API_URL + '/message/' + this.contactId + '/'+ this.channelDefault +'/image-url', postData)

                this.$emit('product-callback-api', msgData, data.externalId, null)

            } catch (error) {
                if(error.response) {
                    Toast.showToast("error", error.response.data.message)
                } else {
                    Toast.showToast("error", error.message)
                }
                
            }
        },
        sendInfoProductMessage: async function (item, templates, title, event) {
            event.preventDefault();

            try {
                const { data } = await axios.get(process.env.VUE_APP_API_URL + `/cmrc/products-inventory/` + item.id)

                const discount = ((item.price - item.finalPrice) / item.price) * 100;
                item.price = this.formatCurrency(item.price)
                item.finalPrice = this.formatCurrency(item.finalPrice)

                const text = template(templates)({
                    'item': item,
                    data,
                    discount
                })

                if(title == 'Info Stok Retail' || title == 'Info Stok') {
                    this.$emit('copy-info-product', text)
                } else {
                    this.$emit('send-info-product', text, title, title == 'Video' ? item.embedVideo : item.slug)
                }

            } catch (error) {
                console.log("products-message", error);
            }
        },
        searchProducts: async function () {
            try {
                this.isLoading = true

                const config = Object()
                config['params'] = Object()
                config['params']['page'] = this.currentPage
                config['params']['size'] = 25
                config['params']['statusMask'] = this.hidden ? "INCLUDE_INACTIVE" : "ACTIVE_ONLY"
                config['params']['sort'] = 'finalPrice,' + (this.sortDir ? 'asc' : 'desc')
                config['params']['term'] = this.searchTerm
                config['params']['apparelSizes'] = this.selectedVariants.join(',')

                if(this.minPrice > 0) config['params']['minPrice'] = this.minPrice
                if(this.maxPrice > 0) config['params']['maxPrice'] = this.maxPrice
                if(this.selectedColor) config['params']['colors'] = this.selectedColor.id

                const { data } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/product', config)

                const addOther = data.content.map(product => {
                    let temp = []
                    if (product.type == 'VARIED') {
                        product.variants.forEach(variant => {
                            if (variant.optionProperties && variant.optionProperties.base_color) {
                                const check = temp.find(e => e.color == variant.optionProperties
                                    .base_color[0].displayValue)

                                if (!check) {
                                    temp.push({
                                        color: variant.optionProperties.base_color[0]
                                            .displayValue,
                                        list: [variant]
                                    })
                                } else {
                                    const index = temp.map(e => e.color).indexOf(variant
                                        .optionProperties.base_color[0].displayValue)
                                    temp[index].list = [...temp[index].list, variant]
                                }
                            } else {
                                if (temp.length < 1) {
                                    temp.push({
                                        color: 'no_color',
                                        list: [variant]
                                    })
                                } else {
                                    temp[0].list = [...temp[0].list, variant]
                                }

                            }

                        })
                    } else {
                        temp.push({
                            color: 'no_color',
                            list: [{
                                qty: product.qty,
                                optionProperties: {
                                    base_size: [{
                                        displayValue: 'Qty'
                                    }]
                                },
                            }]
                        })
                    }
                    product.other = temp
                    product.isLoading = false

                    return product
                })

                this.productList = addOther
                if (this.productList.length < 0) this.info = "Produk yang dicari tidak ada"
            } catch (error) {
                console.log(error);
            }
            this.isLoading = false
        },

        getColors: async function(val) {
            try {
                this.isLoadingColor = true

                const config = Object()
                config['params'] = Object()
                config['params']['term'] = val || String()
                config['params']['page'] = 0
                config['params']['size'] = 25

                const { data } = await axios.get(process.env.VUE_APP_API_URL + `/cmrc/colors`, config)

                this.colors = data.content

                this.isLoadingColor = false
            } catch (error) {
                this.isLoadingColor = false
                console.log(error);
            }
        },

        refreshStock: async function (item, index) {
            item.isLoading = true;

            try {
                const size = ['XS', 'S', 'S+', 'M-', 'M', 'L', 'XL']

                const { data } = await axios.get(process.env.VUE_APP_API_URL + `/cmrc/products-inventory/` + item.id)

                let temp = []
                let qty = 0

                data.forEach(variant => {
                    qty += variant.qtyBalanceValue.qtyAvailable
                    if (variant.properties[1]) {
                        const check = temp.find(e => e.color == variant.properties[1].name)

                        if (!check) {
                            temp.push({
                                color: variant.properties[1].name,
                                list: [{
                                    id: variant.variantId,
                                    qty: variant.qtyBalanceValue.qtyAvailable,
                                    optionProperties: {
                                        base_size: [{
                                            displayValue: variant.properties[0]
                                                .name
                                        }]
                                    },
                                    sort: size.indexOf(variant.properties[0].name),
                                }]
                            })
                        } else {
                            const index = temp.map(e => e.color).indexOf(variant.properties[1].name)
                            temp[index].list = [
                                ...temp[index].list,
                                {
                                    id: variant.variantId,
                                    qty: variant.qtyBalanceValue.qtyAvailable,
                                    optionProperties: {
                                        base_size: [{
                                            displayValue: variant.properties[0].name
                                        }]
                                    },
                                    sort: size.indexOf(variant.properties[0].name),
                                }
                            ]
                        }
                    } else {
                        if (temp.length < 1) {
                            temp.push({
                                color: 'no_color',
                                list: [{
                                    id: variant.variantId,
                                    qty: variant.qtyBalanceValue.qtyAvailable,
                                    optionProperties: {
                                        base_size: [{
                                            displayValue: variant.properties[0] ? variant.properties[0].name : 'Qty'
                                        }]
                                    },
                                    sort: size.indexOf(variant.properties[0] ? variant.properties[0].name : 'Qty'),
                                }]
                            })
                        } else {
                            temp[0].list = [
                                ...temp[0].list,
                                {
                                    id: variant.variantId,
                                    qty: variant.qtyBalanceValue.qtyAvailable,
                                    optionProperties: {
                                        base_size: [{
                                            displayValue: variant.properties[0] ? variant.properties[0].name : 'Qty'
                                        }]
                                    },
                                    sort: size.indexOf(variant.properties[0] ? variant.properties[0].name : 'Qty'),
                                }
                            ]
                        }
                    }
                })

                const sorted = temp.map(e => {
                    e.list = e.list.sort((a, b) => a.sort - b.sort)
                    return e
                })

                const updatedProduct = {
                    ...item,
                    other: sorted,
                    qty: qty,
                    isLoading: false
                }

                this.productList.splice(index, 1, updatedProduct)
            } catch (error) {
                console.log("refresh stock", error);
            }
            item.isLoading = false;
        },

        searchColorWithDelay(val) {
            clearTimeout(this._timerId);
            this._timerId = setTimeout(() => this.getColors(val), 500);
        },

        formatCurrency(price) {
            let val = (price / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
    },

    watch: {
        searchColor(val) {
            if (val && (val.length === 0 || val.length > 2)) this.searchColorWithDelay(val);
        },
        dialogOrder(val) {
            if(!val) {
                this.note = String()
                this.selectedProduct = Object()
                this.selectedVariantsModal = Array()
            }
        },
        dialogLover(val) {
            if(!val) {
                this.selectedLoverProduct = Object()
                this.selectedLovers = Array()
                this.lovers = Array()
            }
        },
        dialogReview(val) {
            if(!val) {
                this.selectedLoverProduct = Object()
                this.selectedImageReview = Object()
                this.selectedLovers = Array()
                this.reviews = Array()
            }
        }
    },

    mounted: function() {
        this.quickProduct = PRODUCT
    },
}

</script>
